<template>
  <div class="hemAdmin">
    <h4>🤖 Körtid, lunchtid, antal hjälp, hjälptid, summa 🤖</h4>
    <v-container>
      <v-data-table
        dense
        :headers="headers"
        :items="results"
        item-key="name"
        class="elevation-1"
      ></v-data-table>
    </v-container>
  </div>
</template>

<script>
import { mapActions } from "vuex"

export default {
  name: 'AdminHome',
  data: () => ({
    headers: [
      {
        text: 'Startnummer',
        align: 'start',
        value: 'r_start_position',
      },
      { text: 'Rallytid (s)', value: 'r_race_time' },
      { text: 'Lunchtid (s)', value: 'r_lunch_time' },
      { text: 'Hjälp (antal)', value: 'r_helps' },
      { text: 'Hjälptid (s)', value: 'r_help_time' },
      { text: 'Totaltid (s)', value: 'r_sum' }
    ],
    results: []
  }),
  created () {
    this.initialize()
  },
  methods: {
    ...mapActions(["getAllResults"]),
    async initialize () {
      const results = await this.getAllResults()
      results.forEach(result => {
        result.r_race_time = this.formatTime(result.r_race_time)
        result.r_lunch_time = this.formatTime(result.r_lunch_time)
        result.r_help_time = this.formatTime(result.r_help_time)
        result.r_sum = this.formatTime(result.r_sum)
      })

      this.results = results
    },
    // Format time from seconds to h, m and s
    formatTime (time) {
      // Convert to hour, min and sec
      const h = Math.floor(time / 3600)
      const m = Math.floor((time - h*3600) / 60)
      const s = time - h*3600 - m*60
      
      return `${this.padTo2Digits(h)}:${this.padTo2Digits(m)}:${this.padTo2Digits(s)}`
    },
    padTo2Digits (num) {
      return num.toString().padStart(2, '0');
    }
  }
}
</script>

<style>
.hemAdmin{
  padding: 20px 0;
}

</style>